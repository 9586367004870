import React from "react";
import BlogPostPreview from "./blog-post-preview";
import { getBlogUrl } from "../lib/helpers";

import styles from "./blog-post-preview-grid.module.css";

function BlogPostPreviewGrid(props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <div className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node) => (
            <BlogPostPreview
              {...node}
              fullUrl={`${props.rootUrl}${getBlogUrl(node.slug.current)}`}
            />
          ))}
      </div>
      {/* {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )} */}
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default BlogPostPreviewGrid;
