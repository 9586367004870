import React from "react";
import { graphql } from "gatsby";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import typographyStyles from "../components/typography.module.css";
import Layout from "../containers/layout";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    siteMeta: site {
      siteMetadata {
        siteUrl
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawSiteName
      mainTitle
      mainMetaTitle
      mainMetaDescription
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const rootUrl =
    data.siteMeta && data.siteMeta.siteMetadata && `${data.siteMeta.siteMetadata.siteUrl}`;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  let mainImage = null;
  if (postNodes.length > 0 && postNodes[0].mainImage) {
    mainImage = postNodes[0].mainImage;
  }

  return (
    <Layout>
      <SEO
        lang={"nl"}
        fullUrl={rootUrl}
        image={mainImage}
        metaTitle={site.mainMetaTitle}
        metaDescription={site.mainMetaDescription}
      />
      <Container>
        <h1 className={typographyStyles.responsiveTitle2}>{site.mainTitle}</h1>
        {postNodes && (
          <BlogPostPreviewGrid title="Laatste artikelen" rootUrl={rootUrl} nodes={postNodes} />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
